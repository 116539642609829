const POPULAR_SEARCH_SCHEME = [
  {
    title: 'Income from self-employment',
    searchKeyWord: 'income self-employment',
  },
  {
    title: 'Foreign nationals',
    searchKeyWord: 'foreign nationals',
  },
  {
    title: 'Overseas customers',
    searchKeyWord: 'overseas customers',
  },
  {
    title: 'Buy to Lets in background',
    searchKeyWord: 'buy to lets in background',
  },
  {
    title: 'Second property',
    searchKeyWord: 'econd property',
  },
];

export default POPULAR_SEARCH_SCHEME;