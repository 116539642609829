import React from "react";

import GeneralButton from "../generalButton/generalButton.component";

import POPULAR_SEARCH_SCHEME from "./popular-searches.utils";

const PopularSearches = ({ setSearch, popularSearches = POPULAR_SEARCH_SCHEME, onClick }) => (
  <section className="popular-searches">
    <h2>Popular searches</h2>
    <p>Most searched terms recently in the site</p>
    {popularSearches.length && (
      <ul>
        {popularSearches.map(({ title, searchKeyWord }) => (
          <li key={title}>
            <GeneralButton
              isBordered
              ariaLabel={`Filter for ${title}`}
              type="button"
              onClick={() => {
                setSearch && setSearch(searchKeyWord);
                onClick(title);
              }}
            >
              {title}
            </GeneralButton>
          </li>
        ))}
      </ul>
    )}
  </section>
);

export default PopularSearches;
