import React, { useEffect, useState, useContext } from 'react';

import { MenuContext } from '../../context'

import GeneralButton from '../generalButton/generalButton.component'

const FilterBar = ({ setFilter, filterKey }) => {
  const menuContext = useContext(MenuContext);

  const [filterValue, setFilterValue] = useState('');

  useEffect(() => {
    setFilterValue(filterKey.toLowerCase())
  }, [filterKey])

  const onReset = () => {
    setFilterValue('');
    setFilter('');
  }

  const onSubmit = event => {
    event.preventDefault();
    setFilter((filterValue.toLowerCase()) || filterKey);
  }

  const onChange = event => {
    const { value } = event.target;
    setFilterValue(value);

    if (!value) {
      setFilter('');
    }
  }

  return (
    <form onSubmit={onSubmit} role='search' className='filter-bar'>
      <label htmlFor='filterBy'>Filter by:</label>
      <div className='field field--input field--inner-submit'>
        <input
          id="filterBy"
          type='text'
          name='filter'
          aria-label="Type something to filter the result"
          value={filterValue || filterKey}
          placeholder='Search'
          onChange={onChange}
          inert={menuContext.isMenuOpen ? "true" : null}
        />
        <span className='design-border'></span>
        <GeneralButton
          type='submit'
          onClick={event => onSubmit(event)}
          ariaLabel='Filter the criterias'
        >
          <span></span>
        </GeneralButton>
        {
          (filterValue.length > 0 || filterKey.length > 0) &&
          <GeneralButton
            type='submit'
            onClick={onReset}
            ariaLabel='Clear filtered result'
          >
            Reset
          </GeneralButton>
        }

      </div>
    </form>
  );
};

export default FilterBar;