import React, { useContext } from "react";

import { MenuContext } from "../../context";

import WHAT_HAS_CHANGED_FILTER from "./what-has-changed.utils";

const WhatHasChanged = ({ setFilter, filterItems = WHAT_HAS_CHANGED_FILTER, isColumn, isReroute, onClick }) => {
  const menuContext = useContext(MenuContext);
  return (
    <section className="what-has-changed">
      <h2>What has changed?</h2>
      <ul className={`${isColumn ? "column" : ""}`}>
        {filterItems.length &&
          filterItems.map(
            ({
              title,
              lastUpdatedAt,
              noChange,
              stressRate,
              variableIncome,
              productSwitch,
              loanToIncome,
              remortgage,
              links,
              link1,
              link1Href,
              link2,
              link2Href,
              offerExtension,
              lendingIntoRetirement,
              partAndPart,
            }) => (
              <li key={lastUpdatedAt}>
                <span className="title">{title}</span>
                {noChange ? (
                  <span className="no-change">No changes</span>
                ) : stressRate ? (
                  <button
                    role="presentation"
                    type="button"
                    aria-label={`View ${title} update`}
                    onClick={() => setFilter(title)}
                    inert={menuContext.isMenuOpen ? "true" : null}
                  >
                    Buy to let stress rate
                  </button>
                ) : variableIncome && productSwitch ? (
                  <div>
                    <button
                      role="presentation"
                      type="button"
                      aria-label={`View ${title} update`}
                      onClick={() => setFilter(title)}
                      inert={menuContext.isMenuOpen ? "true" : null}
                    >
                      Variable income
                    </button>
                    <button
                      role="presentation"
                      type="button"
                      aria-label={`View ${title} update`}
                      onClick={() => setFilter("August 2022 ")}
                      inert={menuContext.isMenuOpen ? "true" : null}
                    >
                      Product switching
                    </button>
                  </div>
                ) : productSwitch && loanToIncome ? (
                  <div>
                    <button
                      role="presentation"
                      type="button"
                      aria-label={`View ${title} update`}
                      onClick={() => setFilter(title)}
                      inert={menuContext.isMenuOpen ? "true" : null}
                    >
                      Loan To Income
                    </button>
                    <button
                      role="presentation"
                      type="button"
                      aria-label={`View ${title} update`}
                      onClick={() => setFilter("November 2022 ")}
                      inert={menuContext.isMenuOpen ? "true" : null}
                    >
                      Product switching
                    </button>
                  </div>
                ) : remortgage ? (
                  <button
                    role="presentation"
                    type="button"
                    aria-label={`View ${title} update`}
                    onClick={() => setFilter("Remortgage & Unencumbered Loan applications")}
                    inert={menuContext.isMenuOpen ? "true" : null}
                  >
                    Remortgage cashback
                  </button>
                ) : links ? (
                  <>
                    <button
                      role="presentation"
                      type="button"
                      aria-label={`View ${title} update`}
                      onClick={() => {
                        onClick(link1);
                        window.open(link1Href, "_blank");
                      }}
                      inert={menuContext.isMenuOpen ? "true" : null}
                    >
                      {link1}
                    </button>
                    <button
                      role="presentation"
                      type="button"
                      aria-label={`View ${title} update`}
                      onClick={() => window.open(link2Href, "_blank")}
                      inert={menuContext.isMenuOpen ? "true" : null}
                    >
                      {link2}
                    </button>
                  </>
                ) : offerExtension ? (
                  <>
                    <button
                      role="presentation"
                      type="button"
                      aria-label={`View ${title} update`}
                      onClick={() => window.open(link1Href, "_blank")}
                      inert={menuContext.isMenuOpen ? "true" : null}
                    >
                      {link1}
                    </button>
                  </>
                ) : lendingIntoRetirement && partAndPart ? (
                  <>
                    <button
                      role="presentation"
                      type="button"
                      aria-label={`View ${title} update`}
                      onClick={() => setFilter("lending into retirement")}
                      inert={menuContext.isMenuOpen ? "true" : null}
                    >
                      Lending into retirement
                    </button>
                    <button
                      role="presentation"
                      type="button"
                      aria-label={`View ${title} update`}
                      onClick={() => window.open(link1Href, "_blank")}
                      inert={menuContext.isMenuOpen ? "true" : null}
                    >
                      {link1}
                    </button>
                  </>
                ) : (
                  ""
                )}
              </li>
            )
          )}
      </ul>
    </section>
  );
};

export default WhatHasChanged;
