import React from "react";
import Accordion from "../accordion/accordion.component";

const AccordionGroup = ({ accordionList = [], title, onAccordionClick }) => (
  <section className="accordion-group" aria-label="Accordion Control Group Buttons" id={title}>
    <h2>{title}</h2>
    {accordionList.length > 0 &&
      accordionList
        .sort((accordion, accordionNext) => {
          if (accordion.title < accordionNext.title) return -1;
          if (accordion.title > accordionNext.title) return 1;
          return 0;
        })
        .map(({ id, title, children, target }) => (
          <Accordion key={title} id={id || null} title={title} target={target} onClick={() => onAccordionClick(title)}>
            {children.map((child, i) => React.cloneElement(child, { key: i }))}
          </Accordion>
        ))}
  </section>
);

export default AccordionGroup;
